import { ProgressIndicator } from "@fluentui/react";
import { Spinner } from "../spinner/Spinner";
import "./RouteLoading.scss";

export function RouteLoading() {
  return (
    <div className="loading-container">
      <ProgressIndicator
        barHeight={8}
        styles={{ itemProgress: { padding: 0 } }}
      />
      <Spinner />
    </div>
  );
}
