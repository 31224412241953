import { useEffect, useMemo } from "react";
import { SEARCH_ROUTE } from "../components/appRouter/Routes";
import { SearchResults } from "../components/search/searchResults";
import { IRouteProps } from "../interfaces/IRouteProps";
import { DocumentService } from "../services/DocumentService";
import { NavService } from "../services/NavService";

export const SEARCH_KEYWORD_QUERY = "q";

export default function Search(props: IRouteProps) {
  const keyword = useMemo(() => {
    return props.searchParams.get(SEARCH_KEYWORD_QUERY) || "";
  }, [props.searchParams]);

  useEffect(() => {
    DocumentService.setTitle(
      `${keyword ? keyword + " - " : ""}${SEARCH_ROUTE.title}`
    );
    NavService.setSearchKeyword(keyword);
  }, [keyword]);

  return (
    <SearchResults
      keyword={keyword}
      location={props.location}
      baseUrl={props.baseUrl}
    />
  );
}
