import { useMemo } from "react";
import { Location } from "react-router-dom";
import { APP_ROUTES } from "../appRouter/Routes";
import { SearchResult } from "./searchResult";

export interface ISearcResultsProps {
  keyword: string;
  location: Location;
  baseUrl: string;
}

export function SearchResults(props: ISearcResultsProps) {
  const searchResults = useMemo(() => {
    return APP_ROUTES.filter(
      (a) =>
        (a.includeInSearch === undefined || a.includeInSearch === true) &&
        (a.title.includes(props.keyword) ||
          a.description.includes(props.keyword) ||
          a.path.includes(props.keyword) ||
          a.keywords.some((k) => k.includes(props.keyword)))
    );
  }, [props.keyword]);
  const noResults = useMemo(() => {
    return searchResults.length === 0;
  }, [searchResults]);

  return (
    <>
      <h1>Search Results</h1>
      {searchResults.map((sr) => (
        <SearchResult
          route={sr}
          key={sr.path}
          baseUrl={props.baseUrl}
          className="mb-2"
        />
      ))}
      {noResults && (
        <div className="text-center">
          <h3>
            No results <i className="bi bi-emoji-frown"></i>
          </h3>
        </div>
      )}
    </>
  );
}
