import { Suspense, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setNavigate } from "../../services/FormService";
import { WindowService } from "../../services/WindowService";
import { Nav } from "../nav/Nav";
import { RouteEffects } from "./RouteEffects";
import { RouteLoading } from "./RouteLoading";
import { APP_ROUTES } from "./Routes";

export function AppRouter() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return (
    <>
      <Nav />
      <div className="page container">
        <Routes>
          {APP_ROUTES.map((route) => {
            return (
              <Route
                path={route.path}
                key={route.path}
                element={
                  <Suspense fallback={<RouteLoading />}>
                    <RouteEffects route={route} />
                    <route.element
                      location={location}
                      searchParams={searchParams}
                      baseUrl={WindowService.baseUrl()}
                    />
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </div>
    </>
  );
}
