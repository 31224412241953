import React, { Suspense } from "react";
import { INavMenuProps } from "./InternalNavMenu";

const LazyNavMenu = React.lazy(() => import("./InternalNavMenu"));

export function NavMenu(props: INavMenuProps) {
  return (
    <Suspense fallback={null}>
      <LazyNavMenu {...props} />
    </Suspense>
  );
}
