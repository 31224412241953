export interface ICardProps {
  className?: string;
}

export function Card(props: React.PropsWithChildren<ICardProps>) {
  return (
    <div className={["card", props.className || ""].join(" ")}>
      <span className="card-accent"></span>
      {props.children}
    </div>
  );
}
