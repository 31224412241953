import { CSSProperties } from "react";

export interface ISpinnerProps {
  style?: CSSProperties;
}

export function Spinner(props: ISpinnerProps) {
  return (
    <div
      className="spinner-border position-absolute"
      style={props.style}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}
