import { useEffect, useState } from "react";
import { SEARCH_KEYWORD_QUERY } from "../../pages/Search";
import { FormService } from "../../services/FormService";
import { NavService } from "../../services/NavService";
import { SEARCH_ROUTE } from "../appRouter/Routes";
import { Logo } from "../logo/Logo";
import { Spinner } from "../spinner/Spinner";
import { NavMenu } from "./NavMenu";

export interface INavProps {}

export function Nav(props: INavProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  useEffect(() => {
    const subscription = NavService.searchKeyword().subscribe((keyword) => {
      setSearchKeyword(keyword);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <nav>
      <button
        className="btn btn-dark btn-menu flex-center"
        onClick={() => {
          setMenuOpen(!menuOpen);
          setMenuLoading(!menuOpen);
        }}
      >
        {!menuLoading && <i className="bi bi-three-dots-vertical"></i>}
        {menuLoading && <Spinner style={{ width: 20, height: 20 }} />}
      </button>
      {menuOpen && (
        <NavMenu
          onClose={() => {
            setMenuOpen(false);
          }}
          onOpening={() => {
            setMenuLoading(false);
          }}
        />
      )}
      <Logo link />
      <form action={SEARCH_ROUTE.path} onSubmit={FormService.onSubmit}>
        <input
          type="search"
          name={SEARCH_KEYWORD_QUERY}
          className="form-control search-input"
          value={searchKeyword}
          onChange={(event) => {
            setSearchKeyword(event.target.value);
          }}
        />
        <button type="submit" className="btn btn-dark btn-submit flex-center">
          <i className="bi bi-search"></i>
        </button>
      </form>
    </nav>
  );
}
