import React, { CSSProperties, useMemo } from "react";

export interface IFadeInDivProps {
  style?: CSSProperties;
}

export function FadeInDiv(props: React.PropsWithChildren<IFadeInDivProps>) {
  const style = useMemo(() => {
    const animation = "fadeIn 0.5s";
    if (props.style) {
      const copy = props.style;
      copy.animation = animation;
      return copy;
    } else {
      return {
        animation,
      };
    }
  }, [props.style]);

  return <div style={style}>{props.children}</div>;
}
