import { Link } from "react-router-dom";
import { IRoute } from "../../interfaces/IRoute";
import { Card } from "../card/Cart";

export interface ISearchResultProps {
  route: IRoute;
  baseUrl?: string;
  className?: string;
  showDescription?: boolean;
}

export function SearchResult(props: ISearchResultProps) {
  return (
    <Card className={props.className}>
      <Link to={props.route.path}>
        {props.baseUrl && (
          <div className="mb-1">{props.baseUrl + props.route.path}</div>
        )}
        <h1 className="mb-1 mt-1">{props.route.title}</h1>
      </Link>
      {props.showDescription !== false && <div>{props.route.description}</div>}
    </Card>
  );
}
