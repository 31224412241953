const TITLE_PREFIX = "Trituga - ";
const _document =
  typeof document !== "undefined"
    ? document
    : {
        title: "",
      };

export const DocumentService = {
  setTitle(title: string) {
    _document.title = `${TITLE_PREFIX}${title}`;
  },
};
