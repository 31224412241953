import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { FadeInDiv } from "../animations/FadeInDiv";
// import { ReactComponent as TritugaLogo } from "../../trigutaLogo.svg";
import { HOME_ROUTE } from "../appRouter/Routes";
import { Card } from "../card/Cart";

export interface ILogoProps {
  link?: boolean;
}

const LogoSvg = React.lazy(() => import("./LogoSvg"));

export function Logo(props: ILogoProps) {
  const content = (
    <>
      <Suspense fallback={<div className="logo"></div>}>
        <FadeInDiv style={{ width: "100%", height: "100%" }}>
          <LogoSvg />
        </FadeInDiv>
      </Suspense>
      <span className="brandname">Trituga</span>
    </>
  );

  return (
    <Card className="logo-card">
      {props.link ? (
        <Link to={HOME_ROUTE.path} style={{ width: "100%" }}>
          {content}
        </Link>
      ) : (
        content
      )}
    </Card>
  );
}
