import { NavigateFunction } from "react-router-dom";

let _navigate: NavigateFunction;
export function setNavigate(navigate: NavigateFunction) {
  _navigate = navigate;
}

export const FormService = {
  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const form = event.nativeEvent.target as HTMLFormElement;

    const inputs = form.getElementsByTagName("input");

    const urlSearchParams = new URLSearchParams();

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs.item(i);

      if (input!.name) {
        urlSearchParams.append(input!.name, input!.value);
      }
    }

    const action = form.getAttribute("action")!;

    _navigate({
      pathname: action,
      search: `?${urlSearchParams.toString()}`,
    });

    return false;
  },
};
