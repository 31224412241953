import { useEffect } from "react";
import { IRoute } from "../../interfaces/IRoute";
import { DocumentService } from "../../services/DocumentService";

export interface IRouteWrapperProps {
  route: IRoute;
}

export function RouteEffects(
  props: React.PropsWithChildren<IRouteWrapperProps>
) {
  useEffect(() => {
    DocumentService.setTitle(props.route.title);
  }, [props.route.title]);
  return <>{props.children}</>;
}
