import { Observable, ReplaySubject } from "rxjs";
import { distinct } from "rxjs/operators";

let _keywordSubject = new ReplaySubject<string>(1);

export const NavService = {
  setSearchKeyword(keyword: string): void {
    _keywordSubject.next(keyword);
  },
  searchKeyword(): Observable<string> {
    return _keywordSubject.asObservable().pipe(distinct());
  },
};
