const _window =
  typeof window !== "undefined"
    ? window
    : {
        location: {
          protocol: "",
          hostname: "",
          port: "",
        },
      };

export const WindowService = {
  baseUrl(): string {
    return (
      _window.location.protocol +
      "//" +
      _window.location.hostname +
      (_window.location.port ? `:${_window.location.port}` : "")
    );
  },
};
