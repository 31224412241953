import React from "react";
import { IRoute } from "../../interfaces/IRoute";

export const HOME_ROUTE: IRoute = {
  path: "/",
  element: React.lazy(() => import("../../pages/Home")),
  description: "Triguta homepage.",
  title: "Home",
  keywords: ["home"],
};

export const SEARCH_ROUTE: IRoute = {
  path: "/search",
  element: React.lazy(() => import("../../pages/Search")),
  includeInSearch: false,
  includeInMenu: false,
  description: "Search results.",
  title: "Search Results",
  keywords: ["search results"],
};

export const FORUMS_ROUTE: IRoute = {
  path: "/forums",
  element: React.lazy(() => import("../../pages/Forums")),
  description: "Triguta Forums",
  title: "Forums",
  keywords: ["forum", "forums"],
};

export const FORUM_ROUTE: IRoute = {
  path: "/forums/:forumId",
  element: React.lazy(() => import("../../pages/Forum")),
  description: "Triguta Forum",
  title: "Forum",
  keywords: ["forum"],
  includeInMenu: false,
  includeInSearch: false,
};

export const LEXOGRAPHY_ROUTE: IRoute = {
  path: "/lexography",
  element: React.lazy(() => import("../../pages/CharacterAndWordCount")),
  description: "Analyze text and report character and word frequencies",
  title: "Lexography",
  keywords: ["character count", "word count", "lexography"],
};

export const APP_ROUTES = [
  HOME_ROUTE,
  SEARCH_ROUTE,
  FORUMS_ROUTE,
  FORUM_ROUTE,
  LEXOGRAPHY_ROUTE,
];
